import React from 'react';
import { Link } from 'gatsby'
import styled from 'styled-components';

const BtnA = styled.a`
    font-size: 20px;
    color: ${({ theme }) => theme.color.white};
    text-decoration: none;
    padding: .4rem 2rem;
    background-color: ${({ theme }) => theme.color.base};
    transition: all .4s ease-in-out;

    &:hover {
        color: white;
        background-color: ${({ theme }) => theme.color.baseSoftDark};
    }
`;

const BtnL = styled(Link)`
    font-size: 20px;
    color: ${({ theme }) => theme.color.white};
    text-decoration: none;
    padding: .4rem 2rem;
    background-color: ${({ theme }) => theme.color.base};
    transition: all .4s ease-in-out;

    &:hover {
        color: white;
        background-color: ${({ theme }) => theme.color.baseSoftDark};
    }
`;

const BtnB = styled.button`
    font-size: 16px;
    color: ${props => props.colorFont || 'white'};
    text-decoration: none;
    //padding: 1rem 3rem;
    //border-radius: 2rem;
    background-color: ${props => props.col || 'blue'};
    transition: all .4s ease-in-out;

    &:hover {
        color: ${props => props.colorFontHover || 'white'};
        background-color: ${props => props.colorHover || '{({ theme }) => theme.color.base}'};
    }
`;

const Button = ({ children, to, href, target, state, onClick, color, colorHover, colorFont, colorFontHover }) => (
    <>
        {
            to ? (
                <BtnL to={to}>
                    {children}
                </BtnL>
            ) : (
                    href ? (
                        <BtnA
                            href={href}
                            target={target}>
                            {children}
                        </BtnA>
                    ) : (
                            <BtnB
                                col={color}
                                colorHover={colorHover}
                                colorFont={colorFont}
                                colorFontHover={colorFontHover}
                                onClick={onClick}
                                state={state}>
                                {children}
                            </BtnB>))
        }
    </>
);

export default Button;