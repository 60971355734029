import React from "react";

import SEO from '../components/seo/SEO';
import Layout from '../layout/layout2';
import AccordionCard from '../components/accordionCard/AccordionCard'
import { Section } from "../utils/utils";
import AccordionCardSimple from "../components/accordionCard/AccordionCardSimple";

const Kosmetologia = ({ data }) => {

  const allZabiegi = data.allDatoCmsKosmetologiaPage.nodes[0].zabiegi

  return <>
    <SEO
      title='Kosmetologia'
      slug='/kosmetologia' />
    <Layout>
      <Section pt='0'>
        {allZabiegi
          .filter(zabieg => zabieg.model.name === 'Zabieg')
          .map((zabieg) => {
            return (
              <AccordionCard
                key={zabieg.nazwaZabiegu}
                zabiegNazwa={zabieg.nazwaZabiegu}
                zabiegRodzaj={zabieg.rodzajZabiegu}
                zabiegOpis={zabieg.opisZabiegNode.childMarkdownRemark.html}
                zabiegPrzeciwskazania={zabieg.przeciwwskazaniaNode.childMarkdownRemark.html}
                zabiegWskazania={zabieg.wskazaniaNode.childMarkdownRemark.html}
                zabiegIlustracja={zabieg.image.fluid}
                alt={zabieg.nazwaZabiegu}
                skrot={zabieg.intro} />
            )
          })}
      </Section>

      <Section pt='0'>
        <h3>Zabiegi pielęgnacyjne Purles</h3>
        {allZabiegi
          .filter(zabieg => zabieg.model.name === 'Zabieg Purles')
          .map((purle) => {
            return (
              <AccordionCardSimple
                key={purle.nazwaZabiegu}
                zabiegRodzaj={purle.rodzajZabiegu}
                zabiegNazwa={purle.nazwaZabiegu}
                zabiegOpis={purle.opisZabieguNode.childMarkdownRemark.html}
                skrot={purle.intro} />
            )
          })}
      </Section>
    </Layout>
  </>
}
export default Kosmetologia

export const query = graphql`
query {
  allDatoCmsKosmetologiaPage {
    edges {
      node {
        introNode {
          childMarkdownRemark {
            html
          }
        }
        heading
        subHeading
      }
    }
  }

  allDatoCmsKosmetologiaPage {
    nodes {
      zabiegi {
        ... on DatoCmsZabieg {
          nazwaZabiegu
          opisZabiegNode {
            childMarkdownRemark {
              html
            }
          }
          intro
          image {
            fluid(maxWidth: 600, forceBlurhash: false, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          model {
            name
          }
          rodzajZabiegu
          przeciwwskazaniaNode {
            childMarkdownRemark {
              html
            }
          }
          wskazaniaNode {
            childMarkdownRemark {
              html
            }
          }
        }

        ... on DatoCmsZabiegPurle {
          nazwaZabiegu
          rodzajZabiegu
          intro
          model {
            name
          }
          opisZabieguNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
}`