import React from "react"
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Container, media } from '../../utils/utils';
import Button from '../button/Button'

const Header = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-content: center;
  align-items: center;
  justify-items: left;
  background-color: ${({ theme }) => theme.color.baseExtraLight};
  margin-top: 2rem;
  padding: 2rem;
  transition: all .5s;

  ${media.lessThan("medium")`
    grid-template-columns: 1fr;
  `}

  h5 {
   color: ${({ theme }) => theme.color.baseDark};
   //font-family: ${({ theme }) => theme.font.main};
   font-weight: 300;
   text-transform: uppercase;
  }

  h6 {
    font-family: ${({ theme }) => theme.font.main};
    font-weight: 500;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 1px;
  }

  && button {
    justify-self: stretch;
    margin-left: 60%;
    padding: 1rem auto;

    ${media.lessThan("medium")`
      margin-top: 2rem;
      justify-self: center;
      margin-left: 0;
    `}
  }

  p {
    //color: ${({ theme }) => theme.color.baseDark};
  }
`;

const Article = styled.div`
  transition: all 5s;
  background-color: ${({ theme }) => theme.color.white};
  display: ${props => props.display || 'none'};

  p{
    white-space: pre-wrap;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  img {
    max-width: 100%;
  }
`;

const WskazaniaWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin-top: 4rem;

  ${media.lessThan("medium")`
    grid-template-columns: repeat(1, 1fr);
  `}

  h5, li, p {
    color: ${({ theme }) => theme.color.white};
  }

  h5 {
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: 300;
  }

  li {
    //font-weight: 400;
  }

  ul {
    padding-bottom: 2rem;
    list-style-type: circle;
  }
`;

const Wskazania = styled.div`
  background-color: ${({ theme }) => theme.color.base};
  padding: 2rem;
`;

const Przeciwskazania = styled.div`
  background-color: ${({ theme }) => theme.color.sec};
  padding: 2rem;
`;

const ImageWrapper = styled.div`
  float: left;
  width: 50%;
  margin-right: 2rem;
  margin-bottom: 1rem;
  //margin-left: 2rem;
  margin-top: 2rem;

  ${media.lessThan("medium")`
    float: inherit;
    width: 100%;
    margin: 0;
  `}
`;

class AccordionCard extends React.Component {

  state = { clicked: false }

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked })
  }

  render() {

    return (<Container>

      {/*MENU ROZWIJANE*/}
      <Header
      >
        <div>
          <h6>{this.props.zabiegRodzaj}</h6>
          <h5>{this.props.zabiegNazwa}</h5>
          <p>{this.props.skrot === null ? 'Jedno zdanie skrótowo o zabiegu, dla kogo najlepiej i jakie daje efekty.Maksymalnie dwie linijki teksty żeby nie przedobrzyć ;)' : this.props.skrot}</p>
        </div>

        <Button
          color={({ theme }) => theme.color.base}
          colorHover={({ theme }) => theme.color.baseDark}
          colorFont={({ theme }) => theme.color.white}
          colorFontHover={({ theme }) => theme.color.white}
          onClick={this.handleClick}
          state={this.state.clicked}>
          {this.state.clicked ? 'Zwiń' : 'Więcej'}
        </Button>

      </Header>

      {/*ROZWINIĘCIE*/}
      <Article display={this.state.clicked ? 'block' : 'none'}>
        <ImageWrapper>
          <Img
            fluid={this.props.zabiegIlustracja}
            alt={this.props.zabiegNazwa} />
        </ImageWrapper>
        <div dangerouslySetInnerHTML={{ __html: this.props.zabiegOpis }}></div>
        <WskazaniaWrapper>
          <Wskazania>
            <h5>Wskazania:</h5>
            <div dangerouslySetInnerHTML={{ __html: this.props.zabiegWskazania }}></div>
          </Wskazania>
          <Przeciwskazania>
            <h5>Przeciwwskazania:</h5>
            <div dangerouslySetInnerHTML={{ __html: this.props.zabiegPrzeciwskazania }}></div>
          </Przeciwskazania>
        </WskazaniaWrapper>
      </Article>
    </Container>
    )
  }
}
export default AccordionCard