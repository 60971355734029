import React from "react"
import styled from 'styled-components';
import { Container, media } from '../../utils/utils';
import Button from '../button/Button'

const Header = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-content: center;
  align-items: center;
  justify-items: left;
  background-color: ${({ theme }) => theme.color.baseExtraLight};
  margin-top: 2rem;
  padding: 2rem;
  transition: all .5s;

  ${media.lessThan("medium")`
    grid-template-columns: 1fr;
  `}

  h5 {
   color: ${({ theme }) => theme.color.baseDark};
   //font-family: ${({ theme }) => theme.font.main};
   font-weight: 300;
   text-transform: uppercase;
  }

  h6 {
    font-family: ${({ theme }) => theme.font.main};
    font-weight: 500;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 1px;
  }

  button {
    justify-self: right;

    ${media.lessThan("medium")`
      margin-top: 2rem;
      justify-self: center;
    `}
  }
`;

const Article = styled.div`
  transition: all 5s;
  background-color: ${({ theme }) => theme.color.white};
  display: ${props => props.display || 'none'};

  p{
    white-space: pre-wrap;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  img {
    max-width: 100%;
  }
`;

class AccordionCardSimple extends React.Component {

  state = { clicked: false }

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked })
  }

  render() {

    return (<Container>

      {/*MENU ROZWIJANE*/}
      <Header
      >
        <div>
          <h6>{this.props.zabiegRodzaj}</h6>
          <h5>{this.props.zabiegNazwa}</h5>
          <p>{this.props.skrot === null ? 'Jedno zdanie skrótowo o zabiegu, dla kogo najlepiej i jakie daje efekty.Maksymalnie dwie linijki teksty żeby nie przedobrzyć ;)' : this.props.skrot}</p>
        </div>

        <Button
          color={({ theme }) => theme.color.base}
          colorHover={({ theme }) => theme.color.baseDark}
          colorFont={({ theme }) => theme.color.white}
          colorFontHover={({ theme }) => theme.color.baseExtraLight}
          onClick={this.handleClick}
          state={this.state.clicked}>
          Więcej
          </Button>

      </Header>

      {/*ROZWINIĘCIE*/}
      <Article display={this.state.clicked ? 'block' : 'none'}>
        <div dangerouslySetInnerHTML={{ __html: this.props.zabiegOpis }} />
      </Article>
    </Container>
    )
  }
}
export default AccordionCardSimple